<template>
  <!-- product START -->
  <div class="product">

    <!-- product:image START -->
    <div class="image">
      <img
        v-if="product.articleData.images.filter(image => image.includes('500x500'))"
        :src="product.articleData.images.filter(image => image.includes('500x500'))"
        :alt="product.name"
      />
      <img
        v-else
        :src="product.articleData.images[0]"
        :alt="product.name"
      />
      <span
        class="f-like-image-loading-product"
        v-if="!product.articleData.images"
      ></span>
    </div>
    <!-- product:image END -->

    <!-- product:description START -->
    <div class="description">
      <h2 v-html="product.articleData.shortName"></h2>
      <div class="details">
        <p class="highlighted" v-html="product.articleData.fields[0].weight"></p>
        <p>
          <span
            v-if="product.price && product.quantity"
            v-html="`${product.currencySymbol} ${priceFormat(product.singlePrice)}`"
          ></span>
          <span
            v-if="product.isReduced === 1"
            v-html="` / <span class='reduced'>${product.currencySymbol}
              ${priceFormat(product.ordinarySinglePrice)}
            </span>`"
          ></span>
        </p>
      </div>
    </div>
    <!-- product:description END -->

    <!-- product:quantity START -->
    <div class="quantity">

      <!-- product:quantity:price START -->
      <transition name="change-price">
        <span class="price" v-html="`${product.currencySymbol}
         ${priceFormat(product.price)}`"></span>
      </transition>
      <!-- product:quantity:price END -->

      <!-- product:quantity:actions START -->
      <div class="actions">
        <button
          v-if="product.isUniqueLine === 1 && product.isLocked === 1"
          v-on:click="decrease(product)"
          class="btn"
        ></button>
        <button
          v-else-if="product.quantity > 1 && product.isUniqueLine === 0"
          v-on:click="decrease(product)"
          class="btn remove"
        >-</button>
        <button
          v-else-if="product.quantity === 1 && product.isUniqueLine === 1"
          v-on:click="decrease(product)"
          class="btn delete"
        ></button>
        <button
          v-else
          v-on:click="decrease(product)"
          class="btn delete"
        ></button>

        <transition name="scale" mode="out-in">
          <div :key="product.quantity" class="number scale-animation">
            <span v-html="product.quantity"></span>
          </div>
        </transition>
        <button
          v-if="product.isUniqueLine === 1 && product.isLocked === 1"
          class="btn"
        ></button>
        <button
          v-else-if="product.isUniqueLine === 1"
          class="btn"
        ></button>
        <button
          v-else
          v-on:click="increase(product)"
          class="btn add"
        >+</button>

      </div>
      <!-- product:quantity:actions END -->

    </div>
    <!-- product:quantity END -->

  </div>
  <!-- product END -->
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
    },

    increase: {
      type: Function,
      default: () => null,
    },

    decrease: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    priceFormat(price) {
      let activeLang = 'de-DE';
      if (localStorage.getItem('lang') === 'en_US') {
        activeLang = 'en-US';
      }
      return new Intl.NumberFormat(
        activeLang,
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        },
      ).format(price);
    },
  },
  mounted() {
    // this.increase(this.product);

    // this.decrease(this.product);
  },
};
</script>
